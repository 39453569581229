import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import AnchorLink from 'components/common/AnchorLink';
import { ReactComponent as Logosvg } from 'assets/svg/logo.svg';
import { RiCloseLine as CloseIcon } from 'react-icons/ri';
import { HiMenuAlt3 as Burger } from 'react-icons/hi';





const Box = styled.div`
position: sticky;
top: 0;
z-index: 10;
background: rgba(255, 255, 255, 0.9);
backdrop-filter: blur(3px);
//padding: 0 0 5px 0;
`;

const Content = styled.div`
height: ${({ theme }) => theme.headerHeight};
display: grid; 
grid-template-columns: auto 1fr; 
grid-template-rows: 1fr 1fr; 
gap: 0px 0px; 
grid-template-areas: 
"logoarea langarea"
"logoarea menuarea"; 

@media (max-width: ${({ theme }) => theme.mobileMenuBreakPoint}) {
  grid-template-areas: 
"logoarea burger";
grid-template-rows: 1fr; 
}
`;



const Logo = styled.div`
grid-area: logoarea;
height: 100%;
position: relative;
color: #fff;
background: ${({ theme }) => theme.colors.red};
display: flex;
align-items: flex-end;

svg {
  transform: translateX(2px)
}

&::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.red};
  width: 70vw;
  z-index: -1;
}

@media (max-width: ${({ theme }) => theme.mobileMenuBreakPoint}) {
padding-top: 1.7em;
}
`;



const LangMenu = styled.div`
grid-area: langarea;
justify-self: end;
display: flex;
font-size: 16px;

.item {
  display: flex;
  align-items: flex-end;
  padding: .2em .4em;
  cursor: pointer;
  
  &.active {
    background: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.white};
    cursor: default;
  }
}

@media (max-width: ${({ theme }) => theme.mobileMenuBreakPoint}) {
 display: none;
}
`;

const Menu = styled.div`
grid-area: menuarea;
justify-self: end;
padding-top: 10px;
&>* {
  margin-left: 2em;
}
svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: .3em;
}
@media (max-width: ${({ theme }) => theme.mobileMenuBreakPoint}) {
 display: none;
}
`;

const BurgerBox = styled.div`
grid-area: burger;
justify-self: end;
align-self: end;

svg {
  height: auto;
  width: 2em;
  color: ${({ theme }) => theme.colors.red};
  cursor: pointer;
}
@media (min-width: ${({ theme }) => theme.mobileMenuBreakPoint}) {
 display: none;
}
`;


const MobileMenu = styled.div`
position: fixed;
width: 100%;
height: 100%;
background: ${({ theme }) => theme.colors.red};
z-index: 1000;
padding: 4vw;
display: grid; 
grid-template-columns:  1fr auto; 
grid-template-rows: auto 1fr; 
gap: 0px 0px; 
grid-template-areas: 
"mobilelogo mobileburger"
"mobilemenu mobileburger"; 
`;

const Close = styled(CloseIcon)`
grid-area: mobileburger;
width: 2em;
height: auto;
color: #fff;
cursor: pointer;
`;

const MobLogo = styled(Logosvg)`
grid-area: mobilelogo;
`;

const VerticalMenu = styled.div`
grid-area: mobilemenu;
margin-top: 2em;
height: 100%;
display: flex;
flex-flow: column;
align-items: center;
justify-content: center;

.link {
  margin-bottom: 1em;
  font-size: 2.5em;
  color: #fff;
  &:after {
    background: #fff;
    height: 1px;
    display: none;
  }
}
`;

const Header = (props) => {

  const [mobMenuIsOpen, setMobMenuIsOpen] = useState(false)

  const { t, i18n } = useTranslation();


  function changeLang(targetLang) {
    i18n.changeLanguage(targetLang)
  }

  const MenuList = () => {
    return (
      <>
        <AnchorLink mobMenuIsOpen={mobMenuIsOpen} setMobMenuIsOpen={setMobMenuIsOpen} setScrollPosition={props.setScrollPosition} scrollTo={0} >{t('Home')}</AnchorLink>
        <AnchorLink mobMenuIsOpen={mobMenuIsOpen} setMobMenuIsOpen={setMobMenuIsOpen} setScrollPosition={props.setScrollPosition} scrollTo='skills'>{t('Skills')}</AnchorLink>
        <AnchorLink mobMenuIsOpen={mobMenuIsOpen} setMobMenuIsOpen={setMobMenuIsOpen} setScrollPosition={props.setScrollPosition} scrollTo='works'>{t('Works')}</AnchorLink>
        <AnchorLink mobMenuIsOpen={mobMenuIsOpen} setMobMenuIsOpen={setMobMenuIsOpen} setScrollPosition={props.setScrollPosition} scrollTo='bottom' >{t('Contacts')}</AnchorLink>
      </>
    )
  }


  return (
    <>
      {mobMenuIsOpen &&
        <MobileMenu>
          <Close onClick={() => setMobMenuIsOpen(false)} />
          <AnchorLink nounderline mobMenuIsOpen={mobMenuIsOpen} setMobMenuIsOpen={setMobMenuIsOpen} setScrollPosition={props.setScrollPosition} scrollTo={0} > <MobLogo /></AnchorLink>
          <VerticalMenu>
            <MenuList />
          </VerticalMenu>
        </MobileMenu>
      }
      <Box>
        <div className="wrap">
          <Content>
            <Logo>
              <AnchorLink nounderline mobMenuIsOpen={mobMenuIsOpen} setMobMenuIsOpen={setMobMenuIsOpen} setScrollPosition={props.setScrollPosition} scrollTo={0} > <Logosvg /></AnchorLink>
            </Logo>
            <LangMenu>
              {/* <div onClick={() => changeLang('ru')} className={`item ${i18n.language === 'ru' && "active"}`}>ru</div>
              <div onClick={() => changeLang('en')} className={`item ${i18n.language === 'en' && "active"}`}>en</div> */}
            </LangMenu>
            <Menu>
              <MenuList />
            </Menu>
            <BurgerBox><Burger onClick={() => setMobMenuIsOpen(true)} /></BurgerBox>
          </Content>
        </div>
      </Box>
    </>
  );
};

export default React.memo(Header);
