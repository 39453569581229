export const worksData = [
	{	
		title: "Vinyl NFT",
		tags: ['design', 'react app', 'logo'],
		slug:'vinyl',
		items: [
			{title: "Vinyl NFT", color: '#4E5452'},
			{title: "Vinyl NFT", color: '#784337'},
		]
	},
	{	title: "Game Garden",
		tags: ['logo', 'branding'],
		slug:'gg',
		items: [
			{title: "gameGarden", color:'#372C33'},
			{title: "gameGarden", color:'#6A605C'},
			{title: "gameGarden", color:'#897B52'},
			{title: "gameGarden", color:'#6B7772'},
		]
	},
	{	
		title: "Crypto Memes",
		tags: ['design', 'branding', 'react app'],
		slug:'mem',
		items: [
			{title: "Crypto Memes", color: '#666458'},
			{title: "Crypto Memes", color: '#7C7770'},
			{title: "Crypto Memes", color: '#8697A0'},
		]
	},
	{	
		title: "Online broadcast service",
		tags: ['logo', 'design', 'react app'],
		slug:'cast',
		items: [
			{title: "Online broadcast service", color:'#646B88'},
			{title: "Online broadcast service", color:'#819FAA'},
		]
	},
	{	
		title: "ADPlayer media",
		tags: ['logo', 'design', 'react app'],
		slug:'player',
		items: [
			{title: "ADPlayer media", color:'#545162'},
			{title: "ADPlayer media", color:'#53575E'},
			{title: "ADPlayer media", color:'#4A4C50'},
		]
	},
	{	
		title: "Togeze",
		tags: ['logo', 'branding'],
		slug:'tg',
		items: [
			{title: "Togeze", color:'#AAB097'},
		]
	},
	{	
		title: "Boatpilot skipper app",
		tags: ['app design'],
		slug:'skipper',
		items: [
			{title: "Boatpilot skipper app", color:"#CACBD0"},
		]
	},
	{	
		title: "Game interface",
		tags: ['game UI', 'UX', 'interface'],
		slug:'thugame',
		items: [
			{title: "Game interface", color:'#555851'},
			{title: "Game interface", color:'#46524C'},
			{title: "Game interface", color:'#49535A'},
		]
	},
	{	
		title: "Fasting app",
		tags: ['app design'],
		slug:'fasting',
		items: [
			{title: "Fasting app", color:'#E1DCDC'},
			{title: "Fasting app", color:'#CBC8D0'},
		]
	},
	{	
		title: "BP TGE website",
		tags: ['design', 'gatsbyJs app'],
		slug:'tge',
		items: [
			{title: "BP TGE website", color:'#374558'},
		]
	},
	{	
		title: "NDecor brand website",
		tags: ['logo', 'webdesign', 'react app'],
		slug:'decor',
		items: [
			{title: "NDecor", color:'#EDEEEA'},
		]
	},
	{	
		title: "BoatPilot website design",
		tags: ['webdesign', 'gatsbyJs app'],
		slug:'pilot',
		items: [
			{title: "BoatPilot website design", color:'#312F41'},
		]
	},
	// {	
	// 	title: "3Go landing page",
	// 	tags: ['webdesign', 'react app'],
	// 	slug:'go',
	// 	items: [
	// 		{title: "3Go landing page"},
	// 	]
	// },
	

];
