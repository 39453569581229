import styled from "styled-components";
import React from "react";

const SectionBox = styled.section`
  display: block;
  margin-top: ${({ theme }) => theme.margins.max};

`;

const Inner = styled.section`
  display: block;
  position: relative;
  z-index: 5;
`;

const SideHeadline = styled.h2`
 
  margin-top: 0.04em;
  font-size: 11vw;
  font-weight: 700;
  font-family:${({ theme }) => theme.fonts.abril};
  @media (min-width: 900px) {
    position: absolute;
    z-index: -1;
    left: -0.2em;
    color: ${({ theme }) => theme.colors.grayLight};
    line-height: 70%;
  }
`;

const Section = (props) => {


  return (
    <SectionBox>
      <div className={`wrap ${props.fullwidth && "norightpadding"}`}>
      {props.sidetext && <SideHeadline>{props.sidetext}</SideHeadline>}
      <Inner>{props.children}</Inner>
      </div>
    </SectionBox>
  );
};

export default Section;

