import React from "react";
import { useHistory } from "react-router-dom";

const AnchorLink = (props) => {

  let history = useHistory();

  function handleClick() {

    if(props.mobMenuIsOpen) {
      props.setMobMenuIsOpen(false)
    }

    if (props.scrollTo === 'bottom') {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    } else {
      let isPageNested = Boolean(history.location.pathname.substring(1))
      if (isPageNested) {
        history.push("/");
      }
      props.setScrollPosition(props.scrollTo)

    }
  }

  return (
    <span style={props.style} className='link' onClick={handleClick}>
      {props.children}
      {!props.nounderline && <i></i>}
    </span>
  );
};

export default AnchorLink;

