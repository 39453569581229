import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import playfair from "assets/fonts/playfair.ttf";
import abril from "assets/fonts/abril.ttf";
import raleway from "assets/fonts/raleway.ttf";

export const GlobalStyle = createGlobalStyle`
${reset}

@font-face {
  font-family: 'playfair';
  src: url(${playfair}) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'abril';
  src: url(${abril}) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'raleway';
  src: url(${raleway}) format('truetype');
  font-display: swap;
}




* {
  box-sizing: border-box;
}

.target {
  scroll-margin-top: ${({ theme }) => theme.headerHeight};
}

html, body, #root {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-size: clamp(16px, 2vmax, 19px);

}



body {
  font-family:${({ theme }) => theme.fonts.default};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};

}

i {
  font-style: italic;
}

b {
  font-weight: bold;
}




.wrap {
  width: 100%;

  padding: 0 2vw;
  &.norightpadding {
      padding: 0;
    }
  @media (min-width: 900px) {
    padding-left: 20vw !important;


    
  }
}

a, .link {
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue};
  display: inline-block;
  position: relative;
  font-weight: 400;

  i {
    position: absolute;
    top: 100%;
    left: 0;
    height: 2px;
    width: 100%;
    background:#dad9f7;
    transition: .2s;
    transform-origin: 50% 50%;
  }

  &:hover {
    i {
      transform: scaleX(0);
    }
  }
}

img {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}

p {
  margin-bottom: ${({ theme }) => theme.margins.medium};
  line-height: 140%;
  font-weight: 260;

  ul {
    margin: 1em 0;
    list-style-type: circle;
    list-style-position: inside;

    li {
      margin-bottom: .7em;
    }
  }
}

h1 {
  font-size: clamp(25px, 7vmax, 150px);
  font-weight: 700;
  line-height: 90%;
  margin-bottom: 0.1em;
  font-family:${({ theme }) => theme.fonts.abril};
}

h2 {
  font-weight: 250;
  font-size: clamp(30px, 3vmax, 70px);
  line-height:1em;
  margin-top: ${({ theme }) => theme.margins.max};
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: 0.8em;
}

input, textarea {
  width: 100%;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.seablue};
  border-radius: 5px;
  padding: 1em;
  background-color: transparent;
  color: #fff;
  font-family: inherit;
  outline: 0!important;
  font-size: 16px;
  font-weight: 300;
  resize: vertical;

  &:active {
      transform: translate(1,1);
    }
  

  &:focus {
    border-color: ${({ theme }) => theme.colors.blue};
   
  }
  
}

button {
  border: 0;
    border-radius: 3px;
    background-color:${({ theme }) => theme.colors.blue};
    color: #fff;
    padding: 1em 3em;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    transition: transform 0.2s;

    svg {
      margin-left: .7em;
    }

    &:active {
      transform: translate(1px , 1px);
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.gray}
    }

    @media (max-width: 400px) {
    display: block;
    width: 100%;
  }
}

label {
  font-size: 14px;
  margin-bottom: 1em;
  font-weight: 450;
  display: block;
  color: #AABAC7;

  &.required:after {
    content: '●';
    vertical-align: super;
    font-size: 7px;
    margin-left: .4em;
    color: ${({ theme }) => theme.colors.yellow};
  }
}
`;


export const theme = {
  colors: {
    white: "white",
    yellow: "#F8AE2D",
    black: "#2E2E2E",
    violet: "#5853CD",
    red: "#ce0b11",
    gray: "rgb(111 111 111);",
    grayLight: "#EBEBEB",
    blue: "#0e63e8",
    blueLight: "#E0EFF8",
    seablue: "#577D9D",
    green: "#4f9e85",
  },
  margins: {
    max: "40px",
    medium: "20px",
  },
  fonts: {
    default: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
    playfair: "playfair",
    abril: "abril",
    raleway: "raleway",
  },
  headerHeight: "clamp(50px, 8vh, 100px)",
  mobileMenuBreakPoint: "600px",
  
};
