import React, { useState, useEffect, Suspense } from "react";
import { theme, GlobalStyle } from "./assets/css/globalStyle";
import { withRouter } from "react-router";
import styled, { ThemeProvider } from "styled-components";
import { worksData } from "components/data/worksData";
import { Trans, useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Portfolio from 'components/Portfolio';
import { ToastContainer } from 'react-toastify';
import { Switch, Route } from "react-router-dom";
import AnchorLink from 'components/common/AnchorLink';
import smoothscroll from 'smoothscroll-polyfill';
import Skills from'components/Skills';
const Footer = React.lazy(() => import('components/Footer'));


smoothscroll.polyfill();

const ViewPortfolioItem = React.lazy(() => import('components/ViewPortfolioItem'));
const AppElement = styled.div`
  position: relative;
  width: 100%;
  @media (min-height: 760px) {
   padding-bottom: 101vh;
  }
  `;

const Main = styled.div`
  position: relative;
  z-index:6;
  background: #fff;
  z-index: 100;
  `;


const Hero = styled.h1`
  margin-top: ${({ theme }) => theme.margins.max};
  `;


const Live = styled.span`
  color: ${({ theme }) => theme.colors.green};
  font-weight: bold;
  font-family:${({ theme }) => theme.fonts.abril};
  `;

const Slogan = styled.h2`
  margin-bottom: ${({ theme }) => theme.margins.max};
  font-family:${({ theme }) => theme.fonts.default};
  position: relative;
  
  i {
    font-size: 0;
    z-index: -99999;
    left: -9999px;
    position: absolute;
  }
  
  svg {
    display: inline-block;
    width: auto;
    height: 1em;
    vertical-align: baseline;
    margin-bottom: -0.05em;
  }

  `;

const App = React.memo((props) => {


  useEffect(()=>{

    if(!window.location.hostname.includes('oneguyyouneed')) {
      window.location.replace("https://oneguyyouneed.com")
    }
  },[])



  const [worksList] = useState(worksData);

  const { t } = useTranslation();

  const [scrollPosition, setScrollPosition] = useState(false);
  const [pageFullLOaded, setpageFullLOaded] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setpageFullLOaded(true)
    }, 5000);

  }, []);


  const MainPage = React.memo(() => {


    useEffect(() => {

      if (typeof (scrollPosition) === 'string') {
        let element = document.getElementById(`${scrollPosition}`);
        element.scrollIntoView({ behavior: "smooth", block: "start" });
  
      } else if(scrollPosition || scrollPosition === 0) {
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
      }

      setTimeout(() => {
        setScrollPosition(false)
      }, 2000);
  
    }, [scrollPosition]);


 
  
    return (
      <>
        <div className="wrap">
          <Hero>{t('Fullstack  designer')}.</Hero>
          <Slogan>

            {t('From')}  zero <Trans i18nKey="projectTo">to <Live>live</Live> web project  with management & support.</Trans>

          </Slogan>
          <div style={{ maxWidth: '1200px' }}>

            {/* <p>
              We are the first Consulting and Creative agency combining 50%
              business consultants with 50% creatives to create effective growth
              and significant long-lasting value. We are thrilled to provide some
              of the most influential brands in the world from Fortune 500
              companies to innovative startups.
            </p> */}
          </div>

          <p>
            <AnchorLink style={{fontSize: '18px'}} scrollTo='bottom'>Get in touch</AnchorLink>
          </p>
        </div>

        <div style={{ scrollMarginTop: 100 }} id='works'>
          <Portfolio setpageFullLOaded={setpageFullLOaded}  {...{ worksList }} />
        </div>
        <div style={{ scrollMarginTop: 100 }} id='skills'>
          <Skills />
        </div>
      </>
    )
  })

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AppElement id='top'>
          <Main>
            <Header scrollPosition={scrollPosition} setScrollPosition={setScrollPosition} />
            <Switch>
              <Route path="/:id" children={<ViewPortfolioItem setpageFullLOaded={setpageFullLOaded} {...{ worksList }} />}  />
              <Route path="/" exact children={<MainPage />} />
            </Switch>
          </Main>
          <div id='footer' >
          { pageFullLOaded && <Suspense fallback={<div className="loading">Loading...</div>}><Footer /></Suspense> }
          </div>
          <ToastContainer />
      </AppElement>

    </ThemeProvider>
  );
})

export default (withRouter(App))

