import React, { useState, useEffect } from "react";
import Section from "components/common/Section";
import styled from "styled-components";
import PortfolioItem from "components/PortfolioItem";

const Container = styled.section`
display: grid;
grid-template-columns: minmax(0, 1fr);

gap: 30px;
position: relative;

@media (min-width: 500px) {
	grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1500px) {
	grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;


	const Portfolio = React.memo((props) => {


	const [loadedimageList, setLoadedImageList] = useState(1);


	useEffect(() => {

		if(loadedimageList >= props.worksList.length) {
			props.setpageFullLOaded(true)
		}
	
	  }, [loadedimageList]);


	


	const printWorks = props.worksList?.map((item, index) => {
		return (
			<PortfolioItem
				setLoadedImageList={setLoadedImageList}
				loadedimageList={loadedimageList}
				key={index}
				id={index}
				slug={item.slug}
				tags={item.tags}
				title={item.title}
				imageBig={item.imageBig}
				imageSmall={item.imageSmall}
				imageBigWebp={item.imageBigWebp}
				imageSmallWebp={item.imageSmallWebp}
			/>
		);
	});


	return (
		<Section fullwidth sidetext="Works">
			<Container>
				{printWorks}
			</Container>
		</Section>
	);
});

export default React.memo(Portfolio);


