import React from "react";
import Section from "components/common/Section";
import { TagCloud } from 'react-tagcloud'
import styled from "styled-components";

const CloudContainer = styled.div`
min-height: 150px;
background: ${({ theme }) => theme.colors.red};
padding: ${({ theme }) => theme.margins.medium} 0;
color: #fff6f6;

span {
  vertical-align: bottom !important;
  padding: 10px;
  &::selection { background: white; color: red }
}
`;

const MemoTagCloud = React.memo(TagCloud);


const data = [
  { value: 'Figma', count: 100 },
  { value: 'Adobe PS', count: 75 },
  { value: 'Adobe XD', count: 90 },
  { value: 'Sketch', count: 100 },
  { value: 'Illustrator', count: 60 },
  { value: 'HTML5', count: 80 },
  { value: 'CSS3', count: 90 },
  { value: 'SCSS', count: 85 },
  { value: 'ReactJs', count: 85 },
  { value: 'JavaScript', count: 80 },
  { value: 'GatsbyJs', count: 75 },
  { value: 'Git', count: 65 },
  { value: 'WebPack', count: 60 },
  { value: 'RollUp', count: 50 },
  { value: 'Gulp', count: 60 },
  { value: 'Jira', count: 30 },
  { value: 'Trello', count: 40 },
  { value: 'Asana', count: 60 },
  { value: 'Slack', count: 40 },
  { value: 'JetBrains Space', count: 50 },
]


const Skills = (props) => {

  return (
    <Section fullwidth sidetext="Skills">
      <CloudContainer>
        <MemoTagCloud style={{ maxWidth: '1200px' }}
          minSize={18}
          maxSize={40}
          tags={data}
          shuffle={false}
          disableRandomColor
        /></CloudContainer>
    </Section>
  );
};

export default React.memo(Skills);


