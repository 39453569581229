import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Box = styled.div`
position: relative;
cursor: pointer;
`;


const Thumb = styled.picture`
position: relative;
display: block;
width: 100%;
height: auto;
z-index: 5;
object-fit: contain;
padding-top: 76.92%;

img {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}
`;



const TagsBox = styled.div`

z-index: 10;
left: -2px;
text-transform: uppercase;
bottom: 0;
display: grid;
grid-auto-flow: column;
grid-auto-columns: min-content;
gap: 1em;
margin-top: 5px;
transform-origin: 0 100%;
color: ${({ theme }) => theme.colors.gray};
font-size: clamp(11px, 1.7vw, 14px);
white-space: nowrap;
font-weight: 450;
margin-left: .7em;

& > div::before {
	content: '+ ';
}
@media (min-width: 900px) {
	position: absolute;	
	transform: rotate(-90deg);
	margin-top: 0px;
	font-weight: normal;
	margin-left: 0;
}
`;



	const PortfolioItem = React.memo((props) => {

	let history = useHistory();

	function handleClick() {
		history.push(`${props.id}`)
	}


	const PrintTags = () => {
		const listTags = props.tags?.map((item, index) =>
			<div key={index}>{item}</div>
		);
		return listTags ? <TagsBox>{listTags}</TagsBox> : null;
	}

	return (
		<Box onClick={handleClick}>
			<Thumb>
			<source media="(max-width: 799px)" srcSet={require(`assets/img/${props.slug}-small.webp`).default }   />
			<source media="(max-width: 799px)" srcSet={require(`assets/img/${props.slug}-small.jpg`).default} />
			<source media="(min-width: 800px)" srcSet={require(`assets/img/${props.slug}-normal.webp`).default} />
			<source media="(min-width: 800px)" srcSet={require(`assets/img/${props.slug}-normal.jpg`).default} />
			<img onLoad={()=>props.setLoadedImageList(props.loadedimageList + 1)} loading={props.id > 3 ? 'lazy' : 'eager'} src={require(`assets/img/${props.slug}-small.jpg`).default} alt={props.title}></img>
			</Thumb>
			<PrintTags />
		</Box>
	);
});




export default React.memo(PortfolioItem);


